<template>
  <div class="course-detail">
    <a-spin :spinning="spinning">
      <div class="spin-content">
        <div class="title">年级</div>
        <ul class="grades">
          <li
            class="grade-item"
            :class="[
              currentGrade.grade == item.grade ? 'grade-item-select' : '',
            ]"
            v-for="item in gradeList"
            :key="item.grade"
            @click="selectGrade(item)"
          >
            {{ item.grade }}
          </li>
        </ul>
        <!-- 上下册 -->
        <a-divider />
        <ul class="volume">
          <li
            class="volume-item"
            :class="[
              currentSemester.semester == item.semester
                ? 'volume-item-select'
                : '',
            ]"
            v-for="item in semesters"
            :key="item.semester"
            @click="selectSemester(item)"
          >
            {{ item.semester }}
          </li>
        </ul>
        <a-divider />
        <!-- 单元 -->
        <div class="title">单元</div>
        <ul class="grades-unit">
          <li
            class="grade-unit-item"
            v-for="item in units"
            :class="[
              currentUnit.unidType == item.unidType ? 'grade-unit-select' : '',
            ]"
            :key="item.unidType"
          >
            <span class="unit-name" @click.stop="selectUnit(item)">{{
              item.unidType
            }}</span>
          </li>
        </ul>
        <a-divider />
        <!-- 章节 -->
        <ul class="unit-child">
          <li class="unit-item" v-for="item in parties" :key="item.LessonId">
            <div class="part">
              <img
                class="arrow"
                src="@/assets/breadcrumb.png"
                alt=""
                srcset=""
              />
              <p class="part-title">{{ item.LessonName }}</p>
              <a-tag v-if="item.AuditState == 0" color="#108ee9">未提交</a-tag>
              <a-tag v-if="item.AuditState == 2" color="#87d068">已通过</a-tag>
              <a-tag v-if="item.AuditState == 3" color="#f50">已驳回</a-tag>
              <a-tag v-if="item.Extend1 != ''" color="#108ee9">精品</a-tag>
              <div class="bts-list">
                <a-button
                  v-if="item.AuditState == 1"
                  type="text"
                  class="bt-text"
                  @click="addUnit(item)"
                  >审核通过</a-button
                >
                <a-button
                  v-if="item.AuditState == 1"
                  type="text"
                  class="bt-text"
                  @click="newLession(item)"
                  >驳回</a-button
                >
                <a-button
                  v-if="item.Extend1 == ''"
                  type="text"
                  class="bt-text"
                  @click="addPart(item)"
                  >设为精品</a-button
                >
              </div>
            </div>
            <ul class="part-child">
              <li
                class="part-child-item"
                v-for="obj in item.myLessonSection"
                :key="obj.SectionId"
                @click="selectPart({ item: obj, name: item.LessonName })"
              >
                <img src="@/assets/link.png" alt="" srcset="" />
                <label>{{ obj.SectionName }}</label>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </a-spin>
    <a-modal
      :visible="partVisible"
      :title="currentTitle"
      :confirm-loading="partConfirmLoading"
      @ok="sureAddPart"
      @cancel="partVisible = false"
      cancelText="取消"
      okText="确定"
    >
      <p>是否确认此操作-{{ currentAction }}？</p>
    </a-modal>
  </div>
</template>

<script>
import {
  getCourseInfoByCourseName,
  getUnitInfoByIdForCheck,
  auditLesson,
  setJinPin,
} from "@/api/review";
export default {
  components: {},
  data() {
    return {
      gradeList: [],
      semesters: [],
      currentSemester: {},
      units: [],
      currentGrade: {},
      currentUnit: {},
      parties: [],
      spinning: true,
      currentTitle: "",
      currentAction: "",
      partVisible: false,
      partConfirmLoading: false,
      currentlessonid: "", //当前选择审核课程ID
    };
  },
  mounted() {
    this.$store.commit("M_ADD_BOOK_ROUTER", [
      {
        path: this.$route.fullPath,
        title: this.$route.query.course,
      },
    ]);
    this.requestInfo();
  },
  methods: {
    requestInfo() {
      getCourseInfoByCourseName({ course: this.$route.query.course }).then(
        (res) => {
          console.log("courseInfo", res);
          this.gradeList = res.data.gradeList;
          if (this.gradeList.length > 0) {
            this.selectGrade(this.gradeList[0]);
          } else {
            this.spinning = false;
          }
        }
      );
    },
    /**
     * 选择年级
     */
    selectGrade(item) {
      this.currentGrade = item;
      this.semesters = item && item.semesterList ? item.semesterList : [];
      this.selectSemester(this.semesters[0]);
    },
    /*
     * 选择上下册
     */
    selectSemester(item) {
      this.currentSemester = item;
      this.units = this.currentSemester.unitList;
      this.selectUnit(item.unitList[0]);
    },
    /*
     * 选择单元
     */
    selectUnit(item) {
      this.spinning = true;
      this.currentUnit = item;
      getUnitInfoByIdForCheck({ unitId: item.unidId }).then((res) => {
        console.log("unit part", res);
        this.parties =
          res.data && res.data.lessonList ? res.data.lessonList : [];
        this.spinning = false;
      });
    },
    /**
     * 选择了课时
     */
    selectPart(item) {
      console.log(item);
      let query = {
        title: `${this.currentGrade.grade}/${this.currentSemester.semester}/${this.currentUnit.unidType}/${item.name}/${item.item.SectionName}`,
        unitId: this.currentUnit.unidId,
        course: this.$route.query.course,
        sectionId: item.item.SectionId,
      };
      this.$router.push({ name: "reviewLessonDesign", query });
    },
    //审核通过
    addUnit(item) {
      this.currentTitle = item.LessonName;
      this.currentlessonid = item.LessonId;
      this.currentAction = "审核通过";
      this.partVisible = true;
    },
    //审核驳回
    newLession(item) {
      this.currentTitle = item.LessonName;
      this.currentAction = "驳回";
      this.partVisible = true;
      this.currentlessonid = item.LessonId;
    },
    //设为精品
    addPart(item) {
      this.currentTitle = item.LessonName;
      this.currentAction = "设为精品";
      this.partVisible = true;
      this.currentlessonid = item.LessonId;
    },
    //确认操作
    sureAddPart() {
      this.partConfirmLoading = true;

      if (this.currentAction == "审核通过") {
        //审核通过
        auditLesson({
          lessonid: this.currentlessonid,
          state: 2,
        }).then((res) => {
          this.partVisible = false;
          this.partConfirmLoading = false;
          this.$message.success(res.msg);
          // this.requestInfo();
          this.selectUnit(this.currentUnit);
        });
      } else if (this.currentAction == "驳回") {
        //驳回课程
        auditLesson({
          lessonid: this.currentlessonid,
          state: 3,
        }).then((res) => {
          this.partVisible = false;
          this.partConfirmLoading = false;
          this.$message.success(res.msg);
          this.selectUnit(this.currentUnit);
        });
      } else {
        //设为精品
        setJinPin({
          lessonid: this.currentlessonid,
        }).then((res) => {
          this.partVisible = false;
          this.partConfirmLoading = false;
          this.$message.success(res.msg);
          this.selectUnit(this.currentUnit);
        });
      }
    },
  },
};
</script>

<style lang="less">
.course-detail {
  .title {
    margin-top: 46px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    line-height: 22px;
    color: #2a2a31;
    font-weight: bold;
  }
  .grades {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    .grade-item {
      margin-right: 36px;
      margin-top: 36px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2a2a31;
      text-align: center;
      padding: 6px 20px;
      background: white;
      cursor: pointer;
      border-radius: 4px;
    }
    .grade-item-select {
      background: #6e76ff;
      color: white;
    }
  }
  .volume {
    padding: 24px 0;
    display: flex;
    .volume-item {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2a2a31;
      margin-right: 36px;
      cursor: pointer;
    }
    .volume-item-select {
      color: #6e76ff;
    }
  }
  .grades-unit {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .grade-unit-item {
      margin-right: 36px;
      margin-top: 36px;
      overflow: visible;
      border-radius: 4px;
      .unit-name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a2a31;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 6px 20px;

        &.add-part {
          background-color: #fff;
          border: none;
        }
      }
    }
    .grade-unit-select {
      color: white;
      .unit-name {
        background: #6e76ff;
        color: white;
      }
    }
  }
  .unit-child {
    padding: 0;

    .grade-unit-item {
      margin-right: 36px;
      margin-top: 36px;
      overflow: visible;
      border-radius: 4px;
      .unit-name {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2a2a31;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 6px 20px;

        &.add-part {
          background-color: #fff;
          border: none;
        }
      }
    }

    .unit-item {
      padding-top: 30px;
      padding-left: 0px;
      .part {
        display: flex;
        align-items: center;
        .arrow {
          width: 8px;
          height: 8px;
          object-fit: contain;
        }
        .part-title {
          margin: 0;
          padding-right: 20px;
          padding-left: 8px;
          font-size: 16px;
          color: #2a2a31;
          font-weight: bold;
        }
        .bts-list {
          margin-left: 40px;

          .bt-text {
            color: #6e76ff;
            border: none;
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
      .part-child {
        padding: 0;
        margin-left: 16px;
        display: flex;
        .part-child-item {
          font-size: 14px;
          padding: 4px 34px 4px 13px;
          background: white;
          margin-right: 14px;
          margin-top: 15px;
          cursor: pointer;
          label {
            padding-left: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>