import request from '../utils/request'

const apis = {
    AuditLesson: '/api/NewLesson/AuditLesson',
    SetJinPin: '/api/NewLesson/SetJinPin',
    GetUnitInfoByIdForCheck: '/api/NewLesson/GetUnitInfoByIdForCheck',
    CourseInfo: '/api/NewLesson/GetLessonLstByCourse',
    GetAllLessonHall: '/api/NewLesson/GetAllLessonHall',
}

/**
 * 获取我的课程
 * @param {*} parameter 
 */
 export function getAllLessonHall(parameter) {
    return request({
        url: apis.GetAllLessonHall,
        method: 'post',
        data: parameter
    })
}


/**
 * 依据科目获取我的课程
 * @param {course:'语文'} params 
 */
 export function getCourseInfoByCourseName(parameter) {
    return request({
        url: apis.CourseInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 课程审核
 * @param {lessonid:'55', state：审核类型，2通过，3驳回} params 
 */
 export function auditLesson(parameter) {
    return request({
        url: apis.AuditLesson,
        method: 'post',
        data: parameter
    })
}

/**
 * 设置精品
 * @param {lessonid，课程id号} params 
 */
 export function setJinPin(parameter) {
    return request({
        url: apis.SetJinPin,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取所有待审核课程，或可设置为精品的课程
 * @param {unitId，单元id号} params 
 */
 export function getUnitInfoByIdForCheck(parameter) {
    return request({
        url: apis.GetUnitInfoByIdForCheck,
        method: 'post',
        data: parameter
    })
}